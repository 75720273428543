import React, { Fragment, useEffect, useContext } from 'react';
import withRouter from 'react-router/withRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { categoryAllFeedAllStoriesRendered } from '../../../../redux/action_creators/category/category_all_feed_action_creators';
import { UserContext } from '../../../components/context/userContext';
import loadable from '@loadable/component';

const FeatureFeed = loadable(() =>
  import('../../../components/feature_feed/feature_feed')
);
const NotFound = loadable(() =>
  import('../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);
const Slug = loadable(() => import('../../../components/slug/slug'));
const VideoModule = loadable(() =>
  import('../../../components/video_module/video_module')
);
const AllFeed = loadable(() => import('../../../components/all_feed/all_feed'));
const MoreStories = loadable(() =>
  import('../../../components/more-stories-component/more-stories-component')
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

const Category = ({
  categoryAllFeed,
  categoryFeaturedFeed,
  categoryScrollingModule,
  categoryPage,
  categoryPromoBanner,
  categoryVideo,
  config,
  featuredSponsoredCard,
  location,
  match,
  status,
  tagAllFeed
}) => {
  const { retriggerPiano } = useContext(UserContext);
  const {
    posts: fcExecutiveBoardArticles,
    tags: fcExecutiveBoardTags
  } = tagAllFeed;
  const tag = get(categoryAllFeed, 'tag.name');
  const category = get(categoryAllFeed, 'category.name', '');
  const categorySlug = get(categoryAllFeed, 'category.slug', '');
  const description = tag
    ? get(categoryAllFeed, 'tag.description')
    : get(categoryAllFeed, 'category.description');
  const page = get(match, 'params.page', false);
  const totalPages = get(categoryAllFeed, 'totalPages');
  const hrefOverride = tag
    ? `/${get(categoryAllFeed, 'category.slug')}/${get(
        categoryAllFeed,
        'tag.slug'
      )}/`
    : '';
  const hideVideoModule = get(
    categoryFeaturedFeed,
    'category.acf.hidePageVideoUnit'
  );
  const type = tag ? `category_tag` : `category`;
  const categoryVideoPlaylist = get(categoryVideo, 'playlist.length');
  const hasFastCompanyExecutiveBoardArticles =
    fcExecutiveBoardArticles && fcExecutiveBoardArticles.length > 0;
  const showFeaturedAdBreak = get(categoryAllFeed, 'posts.length') >= 2;
  const isLastPage = Number(page) === totalPages;

  /**
   * Creates featured feed object from category all feed to replace categoryFeaturedFeed
   * @returns {object} - Returns formatted featured feed object.
   */
  const handleFeaturedFeed = () => {
    const posts = categoryAllFeed?.posts || [];
    return {
      featured: [posts[0]],
      top: posts.slice(1, 6)
    };
  };

  /**
   * Determines whether or not to display the video module for a given tag ID.
   * @param {string} slug - The slug of the category to check.
   * @returns {boolean} - Whether or not to display the video module.
   */
  const shouldDisplayVideoModule = () => {
    if (hideVideoModule) return false;
    return true;
  };

  /**
   * Determines whether category is eligible for Executive Board content.
   * @param {string} slug - The slug of the category to check.
   * @returns {boolean} - Whether or not to display the video module.
   */
  const isEligibleForExecutiveBoard = slug => {
    const includedCategorySlugs = [
      'co-design',
      'technology',
      'work-life',
      'impact',
      'recommender'
    ];
    return includedCategorySlugs.indexOf(slug) !== -1;
  };

  // Alert Piano of SPA category page change
  useEffect(
    () => {
      retriggerPiano(categoryAllFeed);
    },
    [categorySlug]
  );

  if (categoryPage.error || categoryAllFeed.error || status === 404) {
    return (
      <section className="category-page">
        <article className="category-page__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (categoryPage.isLoading) {
    return (
      <section className="category-page">
        <article className="category-page__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  return (
    <main className="category-page">
      <section className="page-title">
        <Slug
          slugName={tag ? `${category} : ${tag}` : category}
          className="category-page"
        />
        {description && (
          <h3
            className="page-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </section>

      {!page && (
        <Fragment>
          <FeatureFeed
            config={config}
            featuredFeed={handleFeaturedFeed()}
            promoBanner={categoryPromoBanner}
            scrollingModule={categoryScrollingModule}
            page="category"
            featuredSponsoredCard={featuredSponsoredCard}
            sectionPath={get(location, 'pathname', '')}
          />

          {showFeaturedAdBreak && (
            <div className="category-page__ff-ad-container">
              <AdContainer type="break" />
            </div>
          )}

          {shouldDisplayVideoModule() && (
            <Fragment>
              <VideoModule
                config={config}
                type="category"
                data={categoryVideo}
                playerid="HliaKwd3"
                stories={categoryVideo.playlist}
              />
              {categoryVideoPlaylist && (
                <div className="category-page__video-ad-container">
                  <AdContainer type="break" />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}

      <AllFeed
        config={config}
        stories={categoryAllFeed?.posts.slice(5, 21) || []}
        allRendered={get(categoryAllFeed, 'allRendered')}
        updateAction={categoryAllFeedAllStoriesRendered}
        paginationEnabled={true}
        page={Number(get(match, 'params.page', '1')) + 1}
        section={categorySlug}
        type={type}
        hrefOverride={hrefOverride}
        isLastPage={isLastPage}
        titleOverride={`All ${tag ? `${category} : ${tag}` : category} Stories`}
        hasSlug={typeof page !== 'string'}
      />

      {!page &&
        isEligibleForExecutiveBoard(categorySlug) &&
        hasFastCompanyExecutiveBoardArticles && (
          <div>
            <MoreStories
              metadata={fcExecutiveBoardArticles.slice(0, 4)}
              slugData={{
                name: 'From the FC Executive Board',
                slug: 'fast-company-executive-board'
              }}
              tags={fcExecutiveBoardTags}
              config={config}
              noBottomBorder={true}
              isPost={false}
            />
          </div>
        )}
    </main>
  );
};

function mapStateToProps(state = {}) {
  return {
    config: state.config || {},
    status: state.status.code,
    categoryScrollingModule: state?.categoryScrollingModule?.data || {},
    categoryFeaturedFeed: {
      source: get(state, 'categoryFeaturedFeed.source', ''),
      featured: get(state, 'categoryFeaturedFeed.featured', []),
      top: get(state, 'categoryFeaturedFeed.top', []),
      category: get(state, 'categoryFeaturedFeed.category', {}),
      error: get(state, 'categoryFeaturedFeed.error', false)
    },
    categoryVideo: {
      playlist: get(state, 'categoryVideo.data.playlist', []),
      title: get(state, 'categoryVideo.data.title', ''),
      feedid: get(state, 'categoryVideo.data.feedid', '')
    },
    categoryPage: get(state, 'categoryPage', {}),
    categoryPromoBanner: get(state, 'categoryPromoBanner.data', {}),
    featuredSponsoredCard: get(state, 'featuredSponsoredCard.data', {}),
    categoryAllFeed: {
      allRendered: get(state, 'categoryAllFeed.data.allRendered', false),
      posts: get(state, 'categoryAllFeed.data.posts', []),
      totalPages: get(state, 'categoryAllFeed.data.totalPages', 0),
      totalPosts: get(state, 'categoryAllFeed.data.totalPosts', 0),
      tag: get(state, 'categoryAllFeed.data.tag'),
      category: get(state, 'categoryAllFeed.data.category'),
      error: get(state, 'categoryAllFeed.error', false)
    },
    tagAllFeed: {
      posts: get(state, 'tagAllFeed.data.posts', []),
      tags: get(state, 'tagAllFeed.data.tags', [])
    }
  };
}

Category.propTypes = {
  status: PropTypes.number.isRequired,
  categoryPage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired,
  categoryScrollingModule: PropTypes.shape({}).isRequired,
  categoryFeaturedFeed: PropTypes.shape({
    source: PropTypes.string,
    featured: PropTypes.arrayOf(PropTypes.shape({})),
    top: PropTypes.arrayOf(PropTypes.shape({})),
    category: PropTypes.object,
    error: PropTypes.bool
  }).isRequired,
  categoryVideo: PropTypes.shape({
    playlist: PropTypes.arrayOf(PropTypes.shape({})),
    feedid: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  categoryPromoBanner: PropTypes.shape({}).isRequired,
  featuredSponsoredCard: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  categoryAllFeed: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  tagAllFeed: PropTypes.shape({}).isRequired
};

const ConnectedClass = connect(mapStateToProps)(Category);
export default withRouter(ConnectedClass);
